body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

.color-true {
  color: #4fbd1b;
}

.color-false {
  color: #e04e10;
}

.bg-true {
  background-color: #4fbd1b;
}

.bg-false {
  background-color: #e04e10;
}

.app {
  background-color: #eee;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.card {
  min-width: 50vw;
  max-width: 50vw;
  min-height: 50vh;
  padding: 2rem;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(11, 113, 145, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.card-header {
  align-self: flex-start;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}
.card-content {
  flex-grow: 1;
}
.card-footer {
  align-self: flex-end;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.home {
  text-align: center;
}
.home img {
  width: 150px;
  height: 150px;
}
.home h1 {
  font-weight: 600;
  font-size: 2.5rem;
  margin: 1rem auto;
}
.home p {
  font-weight: 500;
  line-height: 1.75rem;
  margin: 0;
}
.home-highlight {
  color: #fff;
  margin: 2.5rem auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  border-radius: 5px;
  background-color: #2aa2de;
}
.home-highlight p {
  font-size: 2.5rem;
  line-height: 2.75rem;
}
.home-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.home-footer p {
  margin: 1rem;
  background: transparent;
  font-size: 1.75rem;
  font-weight: 500;
}

.quiz-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.quiz-header img {
  height: 3.25rem;
  width: 3.25rem;
}
.quiz-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.quiz-body p {
  text-align: center;
  font-size: 2rem;
}
.quiz-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
}
.quiz-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 0 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  display: flex;
}
.quiz-footer-btn p {
  margin: 0.75rem;
}

.result-score {
  color: #fff;
  margin: 2.5rem auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  border-radius: 5px;
  background-color: #de8b2a;
}
.result-score p {
  font-size: 2.5rem;
  line-height: 2.75rem;
}
.result-score p {
  margin: 0;
}
.result-list {
  margin-top: 20px;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e3e3e3;
}
.result-list li {
  border-bottom: 1px dashed #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.result-item-l {
  display: flex;
  min-width: 80%;
  max-width: 80%;
}
.result-item-l div {
  margin-left: 1rem;
}
.result-item-num {
  color: #9d9d9d;
}
.result-item-answer {
  color: #9d9d9d;
  font-style: italic;
}

@media (max-width: 700px) {
  .card {
    max-width: 90vw;
  }
}/*# sourceMappingURL=main.css.map */